<template>
  <popup-layout class="middle_size favorite" :class="{ open: isOpen }">
    <template #title>
      <h2 class="font_h6 color_text">
        관심사 설정
      </h2>
      <p class="font_p3 mb24">
        최소 3개~ 최대 10개
      </p>
    </template>
    <template #contents>
      <ul class="list_check">
        <li v-for="interest in interestList" :key="interest.comCd">
          <div class="check_text_2">
            <label>
              <input v-model="selectedInterest" type="checkbox" name="interest" :value="interest.comCd">
              <span>{{ interest.codeName }}</span>
            </label>
          </div>
        </li>
      </ul>
    </template>
    <template #btn>
      <div class="btn_right m_num2">
        <button type="button" class="btn_ty_01 size_1" @click.prevent="close">
          닫기
        </button>
        <button type="button" class="btn_ty_01 solid size_1" @click.prevent="update">
          변경하기
        </button>
      </div>
    </template>
  </popup-layout>
</template>

<script setup lang="ts">
import { useCodeStore } from '~/modules/globalCode/store/codeStore'
import { MST_INTEREST } from '~/modules/globalCode/types/code'

const codeStore = useCodeStore()
const selectedInterest = ref<string[]>([])
const interestList = ref<any[]>([])

const snackbar: any = inject('snackbar')

const isOpen = ref(false)

const emit = defineEmits<{(e: 'update', selectedInterest: string[]): void}>()

onMounted(() => {
  interestList.value = codeStore.list.find((code: any) => code.groupCd === MST_INTEREST)?.codeList
})

const open = (selected: any[]) => {
  selectedInterest.value = selected ?? []

  const htmlElement = document.querySelector('html')
  htmlElement?.classList.add('fixed')
  isOpen.value = true
}

const close = () => {
  const htmlElement = document.querySelector('html')
  htmlElement?.classList.remove('fixed')
  isOpen.value = false
}

const update = () => {
  if (selectedInterest.value.length < 3) {
    snackbar && snackbar.info('관심사를 3개 이상 선택해주세요')
    return
  }
  emit('update', selectedInterest.value)

  close()
}

watch(() => selectedInterest.value, (newInterests, oldInterests) => {
  if (newInterests.length > 10) {
    selectedInterest.value = oldInterests
  }
})

defineExpose({
  open,
  close
})

</script>
<style scoped>
@media screen and (max-width: 768px) {
  .popup_layer.favorite .popup_in{padding-bottom:74px;}
  .popup_layer.favorite .popup_contents{max-height: calc(100vh - 343px); overflow-y: auto;}
}
</style>
